package org.example.kobweblearning.components

import com.varabyte.kobweb.compose.ui.graphics.Color

object Res {
    enum class Theme(val color: Color) {
        BLUE(color = Color.rgb(r = 33, g = 150, b = 243)),
        LIGHT_BLUE(color = Color.rgb(r = 168, g = 236, b = 255)),
        DARK_BLUE(color = Color.rgb(r = 34, g = 63, b = 94)),
        SOCIAL_ICON_BACKGROUND_LIGHT(color = Color.rgb(r = 230, g = 230, b = 230)),
        SOCIAL_ICON_BACKGROUND_DARK(color = Color.rgb(r = 48, g = 82, b = 118)),
        GRADIENT_ONE(color = Color.rgb(r = 161, g = 196, b = 253)),
        GRADIENT_ONE_DARK(color = Color.rgb(r = 19, g = 38, b = 58)),
        GRADIENT_TWO(color = Color.rgb(r = 194, g = 233, b = 251)),
        GRADIENT_TWO_DARK(color = Color.rgb(r = 20, g = 46, b = 73))
    }

    object Icon {
        const val EMAIL_LIGHT = "sdg.png"
        const val EMAIL_DARK = "sdvg.png"
        const val GITHUB = "daire-esas.png"
        const val GITHUB_LIGHT = "daire-esas.png"
        const val INSTAGRAM = "daire-esas.png"
        const val INSTAGRAM_LIGHT = "daire-esas.png"
        const val GITLAB = "daire-esas.png"
        const val GITLAB_LIGHT = "daire-esas.png"
        const val SUN = "daire-esas.png"
        const val MOON = "daire-esas.png"
    }

    object Image {
        const val PROFILE_PHOTO = "daire-esas.png"
    }

    object String {
        const val NAME = "Daira Agency"
        const val PROFESSION = "Daira Agency"
        const val ABOUT_ME = "Daira Agency"
        const val BUTTON_TEXT = "Get in touch"
        const val ROBOTO_CONDENSED = "RobotoCondensedBold"
        const val ROBOTO_REGULAR = "RobotoRegular"
        const val MY_EMAIL = "Daira Agency"
        const val SAVED_THEME = "theme"
    }

    object Dimens {
        const val BORDER_RADIUS = 8
        const val MAX_CARD_WIDTH = 1000
        const val MAX_CARD_HEIGHT = 600
        const val ICON_SIZE = 24
        const val ICON_SIZE_LG = 32
    }
}
